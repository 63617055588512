<template>
  <div class="screen-container">
    <div class="screen-title">
      <dv-decoration-8 class="title-left" />
      <dv-decoration-5 class="title-center" />
      <dv-decoration-8 class="title-right" :reverse="true" />
      <div class="title-center-text" @click="initData">{{ screenTitle }}</div>
    </div>
    <div class="screen-box">
      <div class="screen-left-box">
        <dv-border-box-6>
          <div class="screen-left-innerbox">
            <div class="up">
              <div class="item-title">上链动态信息</div>
              <dv-decoration-3 style="width: 35%; height: 15px" />
              <div class="left-inner-item">
                <dv-border-box-13 class="left-inner-item-col">
                  <div
                    class="left-inner-item-detail"
                    @click="handleClickItem('scjyNum')"
                  >
                    <div class="left-inner-item-detail-title">
                      备案生产经营者数
                    </div>
                    <div class="left-inner-item-detail-number">
                      {{ numberData.scjyNum }}
                    </div>
                  </div>
                </dv-border-box-13>
                <dv-border-box-13 class="left-inner-item-col">
                  <div
                    class="left-inner-item-detail"
                    @click="handleClickItem('wlgxNum')"
                  >
                    <div class="left-inner-item-detail-title">
                      往来供销单位备案数
                    </div>
                    <div class="left-inner-item-detail-number">
                      {{ numberData.wlgxNum }}
                    </div>
                  </div>
                </dv-border-box-13>
                <dv-border-box-13 class="left-inner-item-col">
                  <div
                    class="left-inner-item-detail"
                    @click="handleClickItem('sfllNum')"
                  >
                    <div class="left-inner-item-detail-title">
                      进口商品收发货追溯操作
                    </div>
                    <div class="left-inner-item-detail-number">
                      {{ numberData.sfllNum }}
                    </div>
                  </div>
                </dv-border-box-13>
                <dv-border-box-13 class="left-inner-item-col">
                  <div
                    class="left-inner-item-detail"
                    @click="handleClickItem('szsjNum')"
                  >
                    <div class="left-inner-item-detail-title">首站商家数</div>
                    <div class="left-inner-item-detail-number">
                      {{ numberData.szsjNum }}
                    </div>
                  </div>
                </dv-border-box-13>
                <dv-border-box-13 class="left-inner-item-col">
                  <div
                    class="left-inner-item-detail"
                    @click="handleClickItem('sbpcNum')"
                  >
                    <div class="left-inner-item-detail-title">
                      进口冷链食品申报批次数
                    </div>
                    <div class="left-inner-item-detail-number">
                      {{ numberData.sbpcNum }}
                    </div>
                  </div>
                </dv-border-box-13>
                <dv-border-box-13 class="left-inner-item-col">
                  <div
                    class="left-inner-item-detail"
                    @click="handleClickItem('ysclNum')"
                  >
                    <div class="left-inner-item-detail-title">
                      进口冷链运输车次数
                    </div>
                    <div class="left-inner-item-detail-number">
                      {{ numberData.ysclNum }}
                    </div>
                  </div>
                </dv-border-box-13>
                <dv-border-box-13 class="left-inner-item-col">
                  <div
                    class="left-inner-item-detail"
                    @click="handleClickItem('jgyhNum')"
                  >
                    <div class="left-inner-item-detail-title">监管人员人数</div>
                    <div class="left-inner-item-detail-number">
                      {{ numberData.jgyhNum }}
                    </div>
                  </div>
                </dv-border-box-13>
                <dv-border-box-13 class="left-inner-item-col">
                  <div
                    class="left-inner-item-detail"
                    @click="handleClickItem('cyyyNum')"
                  >
                    <div class="left-inner-item-detail-title">
                      备案登记商户从业人员数
                    </div>
                    <div class="left-inner-item-detail-number">
                      {{ numberData.cyyyNum }}
                    </div>
                  </div>
                </dv-border-box-13>
              </div>
            </div>
            <dv-decoration-10
              style="width: 100%; height: 5px; padding: 0 10px"
            />
            <div class="down">
              <div class="item-title">追溯食品交易热度</div>
              <dv-decoration-3 style="width: 35%; height: 15px" />
              <div class="down-content">
                <ve-bar
                  height="100%"
                  :legend="{ show: false }"
                  :data="leftChartData"
                  :settings="{
                    labelMap: {
                      number: '数量',
                    },
                    dataOrder: {
                      label: 'number',
                      order: 'desc',
                    },
                  }"
                  :extend="leftChartExtend"
                  :colors="barColors"
                ></ve-bar>
              </div>
            </div>
          </div>
        </dv-border-box-6>
      </div>
      <div class="screen-right-box">
        <div class="up">
          <div class="map">
            <ve-map
              v-if="showMap"
              style="width: 75%; margin: 0 auto"
              height="100%"
              :title="mapTitle"
              :data="mapChartData"
              :settings="mapChartSettings"
              :extend="
                cityCode
                  ? {
                      series: {
                        left: '15%',
                      },
                    }
                  : {}
              "
              :legend-visible="false"
              :visual-map="visualMap"
              :tooltip="chartDataTooltip"
            ></ve-map>
            <!-- :extend="{
                series: {
                  left: '15%',
                },
              }" -->
            <!-- <ve-map
              style="width: 75%; margin: 0 auto"
              height="100%"
              :data="mapChartData"
              :settings="mapChartSettings"
              :legend="{ show: false }"
              :visual-map="visualMap"
            ></ve-map> -->
          </div>
          <div class="pie">
            <dv-border-box-1>
              <div class="pie-innerbox">
                <div class="pie-innerbox-item">
                  <div class="item-title">生产经营主体类型占比</div>
                  <dv-decoration-3 style="width: 35%; height: 15px" />
                  <div class="pie-innerbox-item-content">
                    <ve-pie
                      height="100%"
                      :colors="pieColors"
                      :data="pieChartData1"
                      :extend="pieChartExtend"
                    ></ve-pie>
                  </div>
                </div>
                <dv-decoration-10
                  style="width: 100%; height: 5px; padding: 0 10px"
                />
                <div class="pie-innerbox-item">
                  <div class="item-title" style="margin-top: 8px">
                    申报进口食品类型占比
                  </div>
                  <dv-decoration-3 style="width: 35%; height: 15px" />
                  <div class="pie-innerbox-item-content">
                    <ve-pie
                      height="100%"
                      :settings="{
                        roseType: 'radius',
                      }"
                      :colors="pieColors"
                      :data="pieChartData2"
                      :extend="pieChartExtend"
                    ></ve-pie>
                  </div>
                </div>
              </div>
            </dv-border-box-1>
          </div>
        </div>
        <div class="down">
          <dv-border-box-8>
            <div class="down-innerbox">
              <div class="title">
                <div
                  style="width: 80%; text-align: center; word-wrap: break-word"
                >
                  {{ downTitle }}
                </div>
              </div>
              <dv-decoration-2
                :reverse="true"
                style="width: 5px; height: 100%"
              />
              <div class="content">
                <ve-histogram
                  height="100%"
                  :settings="{
                    labelMap: {
                      number: '数量',
                    },
                  }"
                  :legend="{ show: false }"
                  :data="cityChartData"
                  :extend="barChartExtend"
                  :colors="barColors"
                ></ve-histogram>
              </div>
            </div>
          </dv-border-box-8>
        </div>
      </div>
    </div>
    <p style="margin: 0 auto; font-size: 10px">
      © &nbsp;技术支持:@奕阳现代(EsunTech.)
    </p>
  </div>
</template>

<script>
import "echarts/lib/component/visualMap";
import { mapGetters } from "vuex";
import { local } from "@/util/util";

export default {
  data() {
    this.barColors = ["#5ab1ef"];
    // this.barGrid = {
    //   top: 30,
    //   bottom: "2%",
    // };
    this.pieColors = [
      "#19d4ae",
      "#5ab1ef",
      "#fa6e86",
      "#ffb980",
      "#c4b4e4",
      "#d49ea2",
      "#5b4947",
      "#7ba3a8",
    ];
    this.mapTitle = {
      // text: 'this.$store.state.settings.mapTitle',
      text: "重点食品申报批次数统计",
      bottom: "2%",
      right: "2%",
      textStyle: {
        color: "#fff",
      },
    };
    this.barChartExtend = {
      series: {
        type: "bar",
        barMaxWidth: "30",
        itemStyle: {
          normal: {
            label: {
              show: true,
              // position:'top',
              textStyle: {
                color: "#fff",
              },
            },
          },
        },
      },
      grid: {
        top: 30,
        bottom: "2%",
      },
      legend: {
        top: "2%",
        type: "scroll",
        textStyle: {
          color: "#fff",
        },
      },
      xAxis: {
        axisLabel: {
          show: true,
          interval: 0, //强制显示X轴所有标签 设置为1则 隔一个标签,显示一个标签
          // rotate: 40, //文字倾斜角度
          textStyle: {
            color: "#fff",
          },
          formatter: function (value) {
            var ret = ""; //拼接加\n返回的类目项
            var maxLength = 4; //每项显示文字个数
            var valLength = value.length; //X轴类目项的文字个数
            var rowN = Math.ceil(valLength / maxLength); //类目项需要换行的行数
            if (rowN > 1) {
              //如果类目项的文字大于3,
              for (var i = 0; i < rowN; i++) {
                var temp = ""; //每次截取的字符串
                var start = i * maxLength; //开始截取的位置
                var end = start + maxLength; //结束截取的位置
                //这里也可以加一个是否是最后一行的判断，但是不加也没有影响，那就不加吧
                temp = value.substring(start, end) + "\n";
                ret += temp; //凭借最终的字符串
              }
              return ret;
            } else {
              return value;
            }
          },
        },
      },
      yAxis: {
        minInterval: 1,
        splitLine: {
          show: true,
          lineStyle: {
            color: "#384267",
            type: "dashed",
          },
        },
        axisLine: {
          lineStyle: {
            color: "#384267",
            width: 1,
            type: "dashed",
          },
          show: true,
        },
        axisLabel: {
          show: true,
          textStyle: {
            color: "#fff",
          },
        },
      },
    };
    this.leftChartExtend = {
      series: {
        type: "bar",
        barMaxWidth: "30",
        itemStyle: {
          normal: {
            label: {
              show: true,
              // position:'top',
              textStyle: {
                color: "#fff",
              },
            },
          },
        },
      },
      grid: {
        top: 30,
        bottom: "2%",
        right: "5%",
      },
      legend: {
        top: "2%",
        type: "scroll",
        textStyle: {
          color: "#fff",
        },
      },
      xAxis: {
        minInterval: 1,
        splitLine: {
          show: true,
          lineStyle: {
            color: "#384267",
            type: "dashed",
          },
        },
        axisLabel: {
          show: true,
          textStyle: {
            color: "#fff",
          },
        },
      },
      yAxis: {
        splitLine: {
          show: true,
          lineStyle: {
            color: "#384267",
            type: "dashed",
          },
        },
        axisLine: {
          lineStyle: {
            color: "#384267",
            width: 1,
            type: "dashed",
          },
          show: true,
        },
        axisLabel: {
          show: true,
          textStyle: {
            color: "#fff",
          },
          formatter: function (value) {
            if (value.length > 8) {
              return `${value.slice(0, 7)}...`;
            }
            return value;
          },
        },
      },
    };
    this.mapChartSettings = {
      // position: "province/"+this.$store.state.settings.settingProvince,
      positionJsonLink: "https://shelldisk.esunadv.com/json/sichuan.json",
      position: "province/sichuan",
      labelMap: {
        number: "数量",
      },
      label: {
        normal: {
          show: true,
          textStyle: {
            color: "#fff",
          },
        },
        emphasis: {
          textStyle: {
            color: "#fff",
          },
        },
      },
      // itemStyle: {
      //   normal: {
      //     areaColor: "#3a7fd5",
      //     borderColor: "#0a53e9", //线
      //     shadowColor: "#092f8f", //外发光
      //     shadowBlur: 20,
      //   },
      //   emphasis: {
      //     areaColor: "#0a2dae", //悬浮区背景
      //   },
      // },
      itemStyle: {
        normal: {
          borderColor: "#2ab8ff",
          borderWidth: 1.2,
          areaColor: "#12235c",
          // shadowColor: "#092f8f", //外发光
          // shadowBlur: 8,
        },
        emphasis: {
          // areaColor: "#2AB8FF",
          areaColor: "rgba(23, 240, 204)",
          borderWidth: 0,
        },
      },
    };
    // this.mapChartSettings = {
    //   positionJsonLink:
    //     "https://geo.datav.aliyun.com/areas_v2/bound/511700_full.json",
    //   position: "511700",
    //   beforeRegisterMap(json) {
    //     // edit data here such as:
    //     // json.features[0].properties.cp = [121.509062, 26.044332]
    //     return json;
    //   },
    //   labelMap: {
    //     num: "数量",
    //   },
    //   label: {
    //     normal: {
    //       show: true,
    //       textStyle: {
    //         color: "#fff",
    //       },
    //     },
    //     emphasis: {
    //       textStyle: {
    //         color: "#fff",
    //       },
    //     },
    //   },
    //   itemStyle: {
    //     normal: {
    //       borderColor: "#2ab8ff",
    //       borderWidth: 1.2,
    //       areaColor: "#12235c",
    //     },
    //     emphasis: {
    //       areaColor: "rgba(23, 240, 204)",
    //       borderWidth: 0,
    //     },
    //   },
    // };
    this.visualMap = {
      min: 0,
      max: 1,
      show: true,
      left: "left",
      top: "bottom",
      seriesIndex: [0],
      textStyle: {
        color: "#24CFF4",
      },
      inRange: {
        color: ["RGB(37,72,190)", "#24CFF4"],
        // 1E62AC
      },
      calculable: true,
    };
    // this.chartExtend = {
    //   legend: {
    //     top: "2%",
    //     type: "scroll",
    //     textStyle: {
    //       color: "#fff",
    //     },
    //   },
    // };
    this.pieChartExtend = {
      series: {
        radius: "50%",
        center: ["50%", "50%"],
      },
      legend: {
        top: "2%",
        type: "scroll",
        textStyle: {
          color: "#fff",
        },
      },
    };
    return {
      downTitle: "",
      numberData: {
        scjyNum: null,
        wlgxNum: null,
        sbpcNum: null,
        szsjNum: null,
        sfllNum: null,
        ysclNum: null,
        jgyhNum: null,
        cyyyNum: null,
      },
      mapChartData: {
        columns: ["name", "number"],
        rows: [],
      },
      pieChartData1: {
        columns: ["name", "number"],
        rows: [],
      },
      pieChartData2: {
        columns: ["name", "number"],
        rows: [],
      },
      cityChartData: {
        columns: ["name", "number"],
        rows: [],
      },
      leftChartData: {
        columns: ["name", "number"],
        rows: [],
      },
      // screenTitle: 'this.$store.state.settings.screenRouter',
      screenTitle: "追溯监控大屏(进口)",
      cityCode: null,
      chartDataTooltip: null,
      showMap: false,
    };
  },
  computed: {},
  mounted() {
    this.cityCode = local.get("user_region");
    if (this.cityCode == "5117") {
      let code = this.cityCode + "00";
      const _this = this;
      fetch(`http://epcback.esunadv.com/getGeo`).then(function (response) {
        if (response.status == 200) {
          _this.mapTitle.text = "重点食品申报批次数统计";
          _this.screenTitle = "食品追溯智慧监管大屏";
          _this.mapChartSettings = {
            positionJsonLink: `http://epcback.esunadv.com/getGeo`,
            position: code,
            beforeRegisterMap(json) {
              // edit data here such as:
              // json.features[0].properties.cp = [121.509062, 26.044332]
              return json;
            },
            labelMap: {
              number: "数量",
            },
            label: {
              normal: {
                show: true,
                textStyle: {
                  color: "#fff",
                },
              },
              emphasis: {
                textStyle: {
                  color: "#fff",
                },
              },
            },
            itemStyle: {
              normal: {
                borderColor: "#2ab8ff",
                borderWidth: 1.2,
                areaColor: "#12235c",
              },
              emphasis: {
                areaColor: "rgba(23, 240, 204)",
                borderWidth: 0,
              },
            },
          };
        }
      });
    }
    // fetch("https://geo.datav.aliyun.com/areas_v2/bound/51170011223_full.json").then(function (response) {

    // });
    this.initData();
  },
  methods: {
    async initData() {
      const loading = this.$loading({
        target: document.querySelector(".screen-container"),
        lock: true,
        text: "数据加载中...",
        spinner: "el-icon-loading",
        background: "rgba(0, 0, 0, 0.7)",
      });
      await Promise.all([
        this.getLeftUpChart(),
        this.getLeftDownChart(),
        this.handleClickItem("scjyNum"),
        this.getMapChart(),
        this.getRightUpChart(),
        this.getRightDownChart(),
      ]);
      loading.close();
      // this.getLeftUpChart();
      // this.getLeftDownChart();
      // this.handleClickItem("scjyNum");
      // this.getMapChart();
      // this.getRightUpChart();
      // this.getRightDownChart();
    },
    async getLeftUpChart() {
      await this.$extraApi.get(`/v1/pc/jkscreen/chart1`).then((response) => {
        const { data } = response;
        this.numberData = Object.assign({}, data);
      });
    },
    async getLeftDownChart() {
      await this.$extraApi.get(`/v1/pc/jkscreen/chart6`).then((response) => {
        const { data } = response;
        this.leftChartData = {
          columns: ["name", "number"],
          rows: data,
        };
      });
    },
    async getMapChart() {
      await this.$extraApi.get(`/v1/pc/screen/chart3`).then((response) => {
        const { data } = response;
        let max = Math.max.apply(
          Math,
          data.map((item) => {
            return item.number;
          })
        );
        this.visualMap.max = max ? max : 1;
        let formatData = data.filter((item) => item.number > 0);
        this.mapChartData = {
          columns: ["name", "number"],
          rows: formatData,
        };
        let self=this
        this.chartDataTooltip = {
          formatter: function (data) {
            return `数量：${
              self.cityChartData.rows.filter((v) =>
                v.name.includes(data.name)
              )[0].number
            }`;
          },
        };
        this.showMap = true;
      });
    },
    async getDownChart(opt) {
      await this.$extraApi
        .get(`/v1/pc/jkscreen/chart2`, { opt })
        .then((response) => {
          const { data } = response;
          this.cityChartData = {
            columns: ["name", "number"],
            rows: data,
          };
        });
    },
    async getRightUpChart() {
      await this.$extraApi.get(`/v1/pc/screen/chart4`).then((response) => {
        const { data } = response;
        this.pieChartData1 = {
          columns: ["name", "number"],
          rows: data,
        };
      });
    },
    async getRightDownChart() {
      await this.$extraApi.get(`/v1/pc/screen/chart5`).then((response) => {
        const { data } = response;
        this.pieChartData2 = {
          columns: ["name", "number"],
          rows: data,
        };
      });
    },
    handleClickItem(key) {
      const keyMap = {
        scjyNum: "备案生产经营者数",
        wlgxNum: "往来供销单位备案数",
        sbpcNum: "进口冷链食品申报批次数",
        szsjNum: "首站商家数",
        sfllNum: "进口商品收发货追溯操作",
        ysclNum: "进口冷链运输车次数",
        jgyhNum: "监管人员人数",
        cyyyNum: "备案登记商户从业人员数",
      };
      this.downTitle = keyMap[key];
      this.getDownChart(key);
    },
  },
};
</script>

<style>
.screen-container {
  background-image: url(./bg.png);
  background-repeat: no-repeat;
  background-size: 100% 100%;
  -moz-background-size: 100% 100%;
  min-height: calc(100vh - 100px);
  width: 100%;
  position: relative;
  display: flex;
  flex-direction: column;
  color: white;
  text-align: left;
}
.screen-title {
  position: relative;
  height: 80px;
  display: flex;
  justify-content: space-between;
  flex-shrink: 0;
}
.item-title {
  height: 20px;
  font-weight: 500;
}
.title-left {
  width: 25%;
  height: 60px;
}
.title-center {
  width: 40%;
  height: 60px;
  margin-top: 30px;
}
.title-center-text {
  position: absolute;
  font-size: 30px;
  font-weight: 700;
  left: 50%;
  top: 15px;
  -webkit-transform: translateX(-50%);
  transform: translateX(-50%);
  cursor: pointer;
}
.title-right {
  width: 25%;
  height: 60px;
}
.screen-box {
  flex: 1;
  display: flex;
  flex-direction: row;
}
.screen-left-box {
  width: 25%;
}
.screen-left-innerbox {
  height: 100%;
  display: flex;
  flex-direction: column;
}
.screen-left-innerbox .up {
  padding: 10px;
  flex: 1;
  display: flex;
  flex-direction: column;
}
.left-inner-item {
  flex: 1;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-around;
}
.left-inner-item-col {
  flex: 0 0 31.33333%;
  height: 30%;
}
.left-inner-item-detail {
  flex: 1;
  padding: 12px;
  height: 100%;
  display: flex;
  flex-direction: column;
  cursor: pointer;
}
.left-inner-item-detail-title {
  padding-top: 15px;
  flex: 1;
  font-size: 12px;
  text-align: center;
  vertical-align: bottom;
}
.left-inner-item-detail-number {
  flex: 1;
  color: #19d4ae;
  font-size: 16px;
  font-weight: bolder;
  text-align: center;
}

.screen-left-innerbox .down {
  padding: 10px;
  flex: 1;
  display: flex;
  flex-direction: column;
}
.screen-left-innerbox .down .down-content {
  flex: 1;
}
.screen-right-box {
  flex: 1;
  display: flex;
  flex-direction: column;
}
.screen-right-box .up {
  flex: 1;
  display: flex;
  flex-direction: row;
}
.screen-right-box .down {
  height: 28%;
  margin: 15px;
}
.screen-right-box .down-innerbox {
  height: 100%;
  padding: 10px;
  display: flex;
  flex-direction: row;
}
.screen-right-box .up .map {
  flex: 1;
}
.screen-right-box .up .map-innerbox {
  flex: 1;
}
.screen-right-box .up .pie {
  width: 40%;
}
.screen-right-box .up .pie-innerbox {
  padding: 18px;
  height: 100%;
  display: flex;
  flex-direction: column;
}
.screen-right-box .up .pie-innerbox .pie-innerbox-item {
  flex: 1;
  display: flex;
  flex-direction: column;
}
.pie-innerbox-item-content {
  flex: 1;
}
.screen-right-box .down .down-innerbox .title {
  flex: 0 0 15%;
  display: flex;
  align-self: center;
  justify-content: center;
}
.screen-right-box .down .down-innerbox .content {
  flex: 1;
}

.ranking-board {
  -webkit-box-shadow: 0 0 3px #00f;
  box-shadow: 0 0 3px #00f;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  background-color: rgba(6, 30, 93, 0.5);
  border-top: 2px solid rgba(1, 153, 209, 0.5);
  padding: 0 30px;
}
/* #d7ffff */
</style>
